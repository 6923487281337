/*eslint-disable */
import request from '../../Utils/curl'

let SendMail = {
  async sendMailToUser (context, userObj) {
    try{
      let post_data = new FormData();

      for (let key in userObj) {
        if (userObj[key]) {
          post_data.append(key, userObj[key]);
        }
      }

      return await request.curl(context, "send_mail_to_user", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at SendMail() and Exception:',err.message)
    }
  }
}

export {
  SendMail
}
